$sidebar-bg-color: rgba(248, 249, 250, 1);
$sidebar-color: black;
$highlight-color: rgb(12, 19, 73);
$submenu-bg-color: rgba(132, 143, 223, 0.3);
$submenu-bg-color-collapsed: #d5d9f2;
$icon-bg-color: rgba(132, 143, 223, 0.7);
$icon-size: 35px !default;
$submenu-indent: 24px !default;
.pro-sidebar {
  .pro-menu {
    padding-top: 10px;
    padding-bottom: 10px;

    > ul {
      > .pro-sub-menu {
        > .pro-inner-list-item {
          position: relative;
          background-color: $submenu-bg-color;
          font-size: 16px;

          > div > ul {
            padding-top: 15px;
            padding-bottom: 15px;
            > li {
              &:focus-within {
                background-color: rgba(132, 143, 223, 0.7);
                font-weight: 600;
                width: 100% !important;
                margin-left: -12px;
                padding-left: 20px;
                padding-right: 0px !important;
                padding-top: 1.5px;
                padding-bottom: 1.5px;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
    a {
      text-decoration: none;
      color: $sidebar-color;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
      }
      &:hover {
        color: $highlight-color;
      }
      &:focus {
        outline: none;
        color: $highlight-color;
        background-color: rgba(132, 143, 223, 0.3) !important;
        font-weight: 600;
      }
    }
  }
  .pro-menu-item {
    font-size: 16px !important;
    &.active {
      color: $highlight-color;
    }

    .suffix-wrapper {
      opacity: 1;
      transition: opacity 0.2s;
    }
    .prefix-wrapper {
      display: flex;
      margin-right: 5px;
      opacity: 1;
      transition: opacity 0.2s;
    }

    > .pro-inner-item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px 35px 8px 20px;
      cursor: pointer;

      &:focus {
        outline: none;
        color: $highlight-color;
        background-color: rgba(132, 143, 223, 0.6);
        font-weight: 600;
      }
      > .pro-icon-wrapper {
        margin-right: 10px;
        font-size: 20px !important;
        width: $icon-size;
        min-width: $icon-size;
        height: $icon-size;
        line-height: $icon-size;
        text-align: center;
        display: inline-block;

        .pro-icon {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
        }
      }

      > .pro-item-content {
        flex-grow: 1;
        flex-shrink: 1;
        @extend %text-truncate;
      }

      &:hover {
        color: $highlight-color;
        .pro-icon-wrapper {
          .pro-icon {
            animation: swing ease-in-out 0.5s 1 alternate;
          }
        }
      }
    }

    &.pro-sub-menu {
      > .pro-inner-item {
        &:before {
          background: $sidebar-color;
        }

        > .pro-arrow-wrapper {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);

          .pro-arrow {
            display: inline-block;
            border-style: solid;
            border-color: $sidebar-color;
            border-width: 0 2px 2px 0;
            padding: 2.5px;
            vertical-align: middle;
            transition: transform 0.3s;
            transform: rotate(-45deg);
          }
        }
      }

      &.open {
        > .pro-inner-item {
          &:before {
            background: transparent !important;
          }
          > .pro-arrow-wrapper {
            .pro-arrow {
              transform: rotate(45deg);
            }
          }
        }
      }

      .pro-inner-list-item {
        padding-left: $submenu-indent;

        .pro-inner-item {
          padding: 0 !important;
          margin: 8px 30px 8px 15px !important;

          > .pro-icon-wrapper {
            background: none;
            width: auto;
            min-width: auto;
            height: auto;
            line-height: auto;
          }
        }
      }
    }

    &:not(.inner-submenu-arrows) {
      .pro-inner-list-item
        .pro-menu-item.pro-sub-menu
        .pro-inner-item
        .pro-arrow-wrapper {
        display: none;
      }
    }
    &.submenu-bullets {
      z .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
        @extend %pro-item-prefix;
      }
    }

    &.shaped {
      .pro-menu-item {
        > .pro-inner-item {
          > .pro-icon-wrapper {
            background-color: $icon-bg-color;
          }
        }
      }
    }

    &.square {
      .pro-menu-item {
        > .pro-inner-item {
          > .pro-icon-wrapper {
            border-radius: 0;
          }
        }
      }
    }
    &.round {
      .pro-menu-item {
        > .pro-inner-item {
          > .pro-icon-wrapper {
            border-radius: 4px;
          }
        }
      }
    }
    &.circle {
      .pro-menu-item {
        > .pro-inner-item {
          > .pro-icon-wrapper {
            border-radius: 50%;
          }
        }
      }
    }
  }

  // styling when the sidebar is collapsed

  &.collapsed {
    .pro-menu {
      > ul {
        > .pro-menu-item {
          > .pro-inner-item {
            > .suffix-wrapper,
            > .prefix-wrapper {
              opacity: 0;
            }
          }
          > .pro-inner-list-item {
            background-color: $submenu-bg-color-collapsed;
            z-index: 111;
            &:focus-within {
              background-color: rgba(132, 143, 223, 0.7) !important;
            }
          }
          position: relative;
          &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            cursor: pointer;
          }
          &.pro-sub-menu {
            position: relative;

            > .pro-inner-item {
              pointer-events: none;

              > .pro-arrow-wrapper {
                display: none;
              }
            }
            > .pro-inner-list-item {
              height: auto !important;
              position: fixed;
              visibility: hidden;
              min-width: 220px;
              max-width: 270px;
              background-color: rgba(0, 0, 0, 0) !important;
              max-height: 100%;
              padding-left: 3px;
              &.has-arrow {
                padding-left: 10px;
              }
              > .popper-inner {
                max-height: 100vh;
                overflow-y: auto;
                background-color: $submenu-bg-color-collapsed;
                padding-left: 20px;
                border-radius: 4px;
              }
            }
            &:hover {
              > .pro-inner-list-item {
                // transition: visibility, transform 0.3s;
                visibility: visible;
              }
              .pro-icon-wrapper {
                .pro-icon {
                  animation: swing ease-in-out 0.5s 1 alternate;
                }
              }
            }

            .pro-inner-list-item {
              .pro-sub-menu-item,
              .pro-inner-item {
                padding: 8px 30px 8px 5px;
              }
            }
          }
        }
      }
    }
  }

  // styles for rtl
  &.rtl {
    .pro-menu {
      .pro-menu-item {
        .prefix-wrapper {
          margin-right: 0;
          margin-left: 5px;
        }
        > .pro-inner-item {
          padding: 8px 20px 8px 35px;
          > .pro-icon-wrapper {
            margin-right: 0;
            margin-left: 10px;
          }
        }

        &.pro-sub-menu {
          > .pro-inner-item {
            > .pro-arrow-wrapper {
              right: auto;
              left: 20px;
              .pro-arrow {
                transform: rotate(135deg);
              }
            }
          }
          &.open {
            > .pro-inner-item {
              > .pro-arrow-wrapper {
                .pro-arrow {
                  transform: rotate(45deg);
                }
              }
            }
          }

          .pro-inner-list-item {
            padding-left: 0;
            padding-right: $submenu-indent;

            .pro-inner-item {
              padding: 8px 15px 8px 30px;
              &:before {
                margin-right: 0;
                margin-left: 15px;
              }
            }
          }
        }
      }
    }

    // styles for rtl and collapsed
    &.collapsed {
      .pro-menu {
        > ul {
          > .pro-menu-item {
            &.pro-sub-menu {
              > .pro-inner-list-item {
                padding-left: 0;
                padding-right: 3px;
                &.has-arrow {
                  padding-right: 10px;
                }
                > .popper-inner {
                  padding-left: 0;
                  padding-right: 20px;
                }
              }

              .pro-inner-list-item {
                .pro-sub-menu-item,
                .pro-inner-item {
                  padding: 8px 5px 8px 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.pro-sidebar .pro-menu .pro-menu-item.open > .pro-inner-item {
  color: $highlight-color !important;
  outline: none !important;
  font-weight: 600 !important;
  background-color: rgba(132, 143, 223, 0.6) !important;
}
.pro-menu-item.active {
  background-color: rgba(132, 143, 223, 0.7);
  font-weight: 600;
  width: 100% !important;
  margin-left: -12px;
  padding-left: 20px;
  padding-right: 0px !important;
  padding-top: 1.5px;
  padding-bottom: 1.5px;
  border-radius: 5px;
}

// .pro-menu-item a.active {
//   background-color: rgba(132, 143, 223, 0.7) !important;
//   font-weight: 600 !important;
//   width: 100% !important;
//   margin-left: -12px !important;
//   padding-left: 20px !important;
//   padding-right: 0px !important;
//   padding-top: 1.5px !important;
//   padding-bottom: 1.5px !important;
//   border-radius: 5px !important;
// }

@import "~react-pro-sidebar/dist/scss/styles.scss";
