.indi-table td,.indi-table th{
    font-size:16px;
    padding:5px 10px 5px 0 !important;
    border:0 solid white !important; 
}

.indi-table tr{
    border:0 solid white !important; 
}
.Customer-font{
    font-size: 21px;
}
#TLbutton{
    position: absolute;
    right: 15px;
    margin-bottom: 4px;
}