.openDash{
   
    padding-left:265px !important;
    transition: 0.3s all ease !important;
}
.closeDash{

    padding-left:100px !important;
    transition: 0.3s all ease !important;
}
.dash-head{
    font-size: 15px !important;
    font-weight: 600 !important;
    padding:15px 10px 15px 13px !important;
}

@media screen and (max-width:768px){
    .openDash{
   
        padding-left:10px !important;
        transition: 0.2s all ease;
    }
    .closeDash{

        padding-left:100px !important;
        transition: 0.2s all ease;
    }
}
.drop-dash select{
    font-family: Nunito,sans-serif;
  font-size: 14px;
  padding: 5px;
}
.dash-rev select{
    font-family: Nunito,sans-serif !important;
    font-size: 14px !important;
    border:0px !important;
    background-color: rgb(239, 242, 247);
    padding: 4px 36px 4px 8px;
    margin:0;
}
.dash-rev .form-control:focus{
    background-color: rgb(239, 242, 247) !important;
}