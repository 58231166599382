

.heebee-logo{
    color:var(--primary-color);
    padding-left: 20px !important;
    font-size: 22px !important;
}
.widthOfSide .heebee-logo{
    display: none;
}
/* #my-sidebar > div.pro-sidebar-inner > div > div.pro-sidebar-content > nav > ul > li > div.react-slidedown.pro-inner-list-item > div > ul > li:focus-within{
background-color: brown !important;
padding:0 !important;
width:100% !important;

} */
