.form-select {
    display: block !important;
    width: 100% !important;
    padding: 0.47rem 1.75rem 0.47rem 0.75rem ;
    -moz-padding-start: calc(0.75rem - 3px) !important;
    font-size: 0.9rem !important;
    font-weight: 400 !important; 
    line-height: 1.5 !important;
    color: #505d69;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
}
.form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.7875rem;
    border-radius: 0.2rem;
}
